import { Component, ElementRef, HostListener, Injector, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddGiftToCartInputDto, UpdateCartInfoInputDto } from '@proxy/carts/dtos';
import { CheckAmazonPriceDto, ItemDetailForFanViewDto } from '@proxy/wish-list/dtos/models';
import { WishListFanViewService } from '@proxy/wish-list/wish-list-fan-view.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, finalize } from 'rxjs';
import { AppComponentBase, WidthType } from 'src/app/shared/app-component-base';
import { CommonComponent } from 'src/app/shared/dft/component/common.component';
import { ConfirmDonatePriceComponent } from '../../fan-view/confirm-donate-price/confirm-donate-price.component';
import { GiftStatus, ProductType } from '@proxy/gifts';
import { ItemDetailCreatorComponent } from '../../creator-view/item-detail-creator/item-detail-creator.component';
import { ConfirmDeleteComponent } from '../../creator-view/confirm-delete/confirm-delete.component';
import { WishListCreatorViewService } from '@proxy/wish-list';
import { ItemDetailCreatorManuallyComponent } from '../../creator-view/item-detail-creator-manually/item-detail-creator-manually.component';
import { GlobalLookupService } from '@proxy/global';
import { isNullOrEmpty } from '@abp/ng.core';
import { NotifyUserHasNotEmailComponent } from './notify-user-has-not-email/notify-user-has-not-email.component';
@Component({
  selector: 'app-item-detail-donated',
  templateUrl: './item-detail-donated.component.html',
  styleUrls: ['./item-detail-donated.component.scss'],
})
export class ItemDetailDonatedComponent extends AppComponentBase implements OnInit {
  id: any;
  data: any;
  formData: FormGroup;
  itemDetail: ItemDetailForFanViewDto;
  images: any[];
  ref: DynamicDialogRef | undefined;
  isview = false;
  isShow = false;
  loadingData = false;
  isSubmit = false;
  isDisable = false;
  imgURLAvatar = 'assets/images/icon/svg/avatardefault.svg';
  link = '';
  isMobileScreen = false;
  isDonate = true;
  isCreatorView = false;
  creatorInfo: any;
  giftStatus = GiftStatus;
  widthType = WidthType;
  base64: any;
  isViewPopupVerifyEmail = false;
  checkInfor: any;
  isViewBtnSendEmail = false;
  emailCreator: string;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobileScreen = window.innerWidth <= 767;
  }
  constructor(
    injector: Injector,
    private fb: FormBuilder,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    public wishListFanViewService: WishListFanViewService,
    public wishListCreatorViewService: WishListCreatorViewService,
    public globalService: GlobalLookupService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private dialogService: DialogService
  ) {
    super(injector);
  }

  activeIndexChange(className: string) {
    this.getScreenSize();
    setTimeout(() => {
      const classActive = '.' + className + ' .p-galleria-thumbnail-items';
      const element = this.elementRef.nativeElement.querySelector(classActive);
      if (element) {
        this.renderer.setStyle(element, 'transform', 'translate3d(0%, 0px, 0px)'); // Thay đổi giá trị transform tùy ý
      }
    }, 1);
  }
  ngOnInit(): void {
    this.loadingData = true;
    setTimeout(() => {
      this.isShow = true;
    }, 1);
    this.initForm();

    this.data = this.dynamicDialogConfig.data;
    const currentUser = this.getCurrentUser();
    if (this.data.idCreator === currentUser.id) {
      this.isDonate = false;
    }
    // Nếu có id thì là tạo mới gift
    if (this.data?.item?.id) {
      this.id = this.data.item.id;
    } else {
      // Nếu có itemInCart thì là edit gift
      if (this.data?.itemInCart?.giftId) {
        this.id = this.data.itemInCart.giftId;
      }
    }

    if (this.data?.isgift !== 0) {
      this.isview = true;
      this.formData.disable();
    } else {
      this.formData.enable();
    }
    this.creatorInfo = this.data?.creatorInfo;
    if (this.creatorInfo) {
      this.creatorInfo.profileImage = !this.creatorInfo.profileImage
        ? this.getAvatarDefault()
        : this.creatorInfo.profileImage;
    }
    if (this.data?.isCreatorView) {
      this.isCreatorView = true;
    }

    this.getData(this.id);

    let el = document.querySelector<HTMLElement>('.p-dialog');
    el.style.boxShadow = 'unset';
  }

  getData(id: any) {
    this.wishListFanViewService
      .getItemDetail(id)
      .pipe(
        finalize(() => {
          this.loadingData = false;
        })
      )
      .subscribe(rs => {
        this.itemDetail = rs;
        this.images = rs.images;
        const url = new URL(this.itemDetail.link);
        const domain = url.hostname;
        this.link = domain;

        // Nếu số tiền còn lại (money) === 0, thì disable button donate
        if (this.getAmount() === 0) {
          this.formData.disable();
          this.isDisable = true;
        }

        // Nếu có itemInCart thì gán giá trị vào form
        if (this.data?.itemInCart) {
          this.formData.controls.Amount.setValue(this.data.itemInCart.fund);
          this.formData.controls.Message.setValue(this.data.itemInCart.message);
        }

        // Kiểm tra xem nếu scroll bar xuất hiện thì thêm class vào list-fan-donate
        setTimeout(() => {
          const element = this.elementRef.nativeElement.querySelector('#list-fan-donate');
          if (element) {
            if (element.scrollHeight > element.clientHeight) {
              this.renderer.addClass(element, 'p-r-scroll-bar');
            }
          }
        }, 0);
      });

    this.userService.getCreatorInforSetting().subscribe(res => {
      this.emailCreator = res.creatorBasicInfor.emailAddress;
    });
  }

  initForm() {
    this.formData = this.fb.group(
      {
        Amount: [undefined, [Validators.required]],
        Message: [undefined],
      },
      { updateOn: 'blur' }
    );
  }

  amountChange(res) {
    const valueTemp = this.convertPriceStringToNum(res.target.value);
    this.formData.controls.Amount.setValue(valueTemp);
    let value = this.formData.controls.Amount.value;
    this.setErrorAmount(value);
  }

  setErrorAmount(value) {
    const money = this.itemDetail.displayMoney - this.itemDetail.totalDonate;
    if (value > money) {
      this.formData.controls.Amount.setErrors({ maximumDonation: true });
    } else if (money > 250 && (value < 250 || value === 0)) {
      // Nếu số tiền còn lại mà lớn hơn ¥250 thì không cho nhập < ¥250
      this.formData.controls.Amount.setErrors({ minimumDonation: true });
    } else if (money > 0 && money < 250 && value === 0) {
      // Nếu số tiền còn lại mà nhỏ hơn ¥250 thì không cho nhập = 0
      this.formData.controls.Amount.setErrors({ minimumDonation1: true });
    } else {
      this.formData.controls.Amount.setErrors(null);
    }
     this.formData.controls.Amount.markAsDirty();
  }

  setAmount(value: number) {
    this.formData.controls.Amount.setValue(value);
  }

  setAmountAll() {
    this.formData.controls.Amount.setValue(
      this.itemDetail.displayMoney - this.itemDetail.totalDonate
    );
  }

  closeDialog(data?: any): void {
    this.dialogRef.close(data);
  }

  checkPriceBeforeAddGift() {
    if (CommonComponent.getControlErr(this.formData) === '') {
      if (this.formData.controls.Amount.value <= 0) {
        this.setErrorAmount(this.formData.controls.Amount.value);
        return;
      }

      this.isSubmit = true;
      if (!this.isDonate) {
        this.showWarningMessage(this.L('::CreatorDonate:WishList:Donatefail'));
        this.isSubmit = false;
        return;
      }
      // Nếu donate full thì gọi lên amz check giá sp xem có tăng hay không
      // if (this.formData.get('Amount').value === this.itemDetail.displayMoney) {
      let input: CheckAmazonPriceDto = {
        giftId: this.id,
        fund: this.formData.controls.Amount.value,
      };
      this.globalService.haveEnoughPaymentInfoAndAddress().subscribe(rs => {
        if (rs.isVerifyEmail) {
          this.wishListFanViewService
            .checkGiftPriceFromAmazonAndGiftByInput(input)
            .pipe(
              catchError(err => {
                this.isSubmit = false;
                this.handlerError(err);
                throw err;
              })
            )
            .subscribe(async res => {
              if (
                res &&
                res.priceNew > this.itemDetail.displayMoney &&
                res.priceStatus === this.PriceStatusType.Increase
              ) {
                this.isSubmit = false;
                res.priceOld = this.itemDetail.displayMoney;
                if (this.formData.get('Amount').value === this.itemDetail.displayMoney) {
                  this.showDialogConfirmDonatePrice(res);
                  // Cập nhật lại giá tiền vào giao diện nếu có sự thay đổi về giá
                  this.itemDetail.displayMoney = res.priceNew;
                }
              } else if (res && res.priceStatus === this.PriceStatusType.Decrease) {
                if(res.isChangeStatus === true) {
                  this.showWarningMessage(
                    this.L('::Notification:Fan:GiftChangeStatusWhenPriceDecrease')
                  );
                  this.closeDialog({data: GiftStatus.Confirmed});
                  return;
                }
                this.isSubmit = false;
                this.itemDetail.displayMoney = res.priceNew;
                this.showDialogConfirmDonatePrice(res);
              } else {
                this.saveData();
              }
            });
        } else {
          this.isViewPopupVerifyEmail = true;
          this.checkInfor = rs;
          if (!this.emailCreator.includes('@example.com')) {
            this.isViewBtnSendEmail = true;
          }
        }
      });
    }
  }

  closeDialogVerifyEmail() {
    this.isViewPopupVerifyEmail = false;
    this.isSubmit = false;
  }

  verifiedEmail() {
    this.userService.sendEmailVerify().subscribe(rs => {
      if (rs) {
        let message = this.localizationService.instant('::CreatorDonate:Profile:SendEmailSucces');
        message = message.replace('{0}', this.emailCreator);
        this.closeDialogVerifyEmail();
        this.showSuccessMessage(message);
      }
    });
  }

  saveData(): void {
    if (!this.data?.itemInCart) {
      const input: AddGiftToCartInputDto = {
        giftId: this.id,
        fund: this.formData.controls.Amount.value,
        message: this.formData.controls.Message.value,
        isDonateAll: this.formData.get('Amount').value === this.itemDetail.displayMoney,
      };

      this.wishListFanViewService
        .addGiftToCart(input)
        .pipe(finalize(() => (this.isSubmit = false)))
        .subscribe(res => {
          setTimeout(() => {
            this.dialogRef.close({ data: this.formData.controls.Amount.value });
          }, 0);
        });
    } else {
      const input: UpdateCartInfoInputDto = {
        id: this.data.itemInCart.id,
        fund: this.formData.controls.Amount.value,
        isDonateAll: this.formData.controls.Amount.value === this.itemDetail.displayMoney,
        message: this.formData.controls.Message.value,
      };

      this.wishListFanViewService.updateCartInfo(input).subscribe(() => {
        this.isSubmit = false;
        setTimeout(() => this.dialogRef.close(input), 0);
      });
    }
  }

  getUrlAvatar(url) {
    this.imgURLAvatar = !url ? this.getAvatarDefault() : this.getImageFromUrl(url, WidthType.W100);
    return this.imgURLAvatar;
  }

  showDialogConfirmDonatePrice(data) {
    let contentStyle = {
      overflow: 'auto',
      padding: '60px 80px',
      borderRadius: '20px',
    };

    if (this.isMobileScreen) {
      contentStyle.padding = '24px';
    }

    this.ref = this.dialogService.open(ConfirmDonatePriceComponent, {
      width: '526px',
      data: data,
      contentStyle: contentStyle,
      baseZIndex: 10000,
      maximizable: false,
      showHeader: false,
      dismissableMask: false, // click ra ngoài để tắt dialog
    });
    this.ref.onClose.subscribe(dataReturn => {
      this.setErrorAmount(this.formData.controls.Amount.value);
      if (dataReturn.data) {
        this.formData.controls.Amount.setValue(dataReturn.data);
        this.saveData();
      }
    });
  }

  getMoneyFormat(money: string, inputNumber: any) {
    return `¥${money.replace(/\B(?=(\d{3})+(?!\d))/g, inputNumber?.groupChar)}`;
  }

  getAmount() {
    return this.itemDetail?.displayMoney - this.itemDetail?.totalDonate;
  }

  deleteGift(id: any) {
    let contentStyle = {
      overflow: 'auto',
      padding: '60px 80px',
      borderRadius: '20px',
    };

    if (this.isMobileScreen) {
      contentStyle = {
        overflow: 'auto',
        padding: '24px',
        borderRadius: '8px',
      };
    }

    this.ref = this.dialogService.open(ConfirmDeleteComponent, {
      width: '526px',
      contentStyle: contentStyle,
      baseZIndex: 10000,
      maximizable: false,
      showHeader: false,
      dismissableMask: false, // click ra ngoài để tắt dialog
    });
    this.ref.onClose.subscribe(dataReturn => {
      if (dataReturn.data) {
        if (
          this.itemDetail.status == GiftStatus.Queued &&
          this.itemDetail?.isFirstDonationDateHasBeen60Days
        ) {
          this.wishListCreatorViewService
            .changeStatusToCancelById(id)
            .pipe(
              catchError(err => {
                this.handlerError(err);
                return err;
              })
            )
            .subscribe(res => {
              this.showSuccessMessage(this.L('::Wishlist:UpdateWishList:SuccessfullyDeleted'));
              this.closeDialog();
            });
        } else {
          this.wishListCreatorViewService
            .deleteWishList(id)
            .pipe(
              catchError(err => {
                this.handlerError(err);
                return err;
              })
            )
            .subscribe(res => {
              this.showSuccessMessage(this.L('::Wishlist:UpdateWishList:SuccessfullyDeleted'));
              this.closeDialog();
            });
        }
      } else {
        this.closeDialog();
      }
    });
  }

  viewDetail(isEdited: any) {
    let contentStyle = {
      overflow: 'auto',
      padding: '40px 40px 40px 32px',
      borderRadius: '8px',
    };
    let width = '814px';
    let height = 'auto';

    if (this.isMobileScreen) {
      contentStyle = {
        overflow: 'auto',
        padding: '24px',
        borderRadius: '8px',
      };
      width = '358px';
      height = '500px';
    }
    if (this.data.item.productType === ProductType.Amazone) {
      this.ref = this.dialogService.open(ItemDetailCreatorComponent, {
        data: { id: this.id, isView: true, isEdit: isEdited },
        width: width,
        height: height,
        styleClass: 'b-r-8',
        contentStyle: contentStyle,
        baseZIndex: 10000,
        maximizable: false,
        showHeader: false,
      });
      this.ref.onClose.subscribe(dataReturn => {
        if (isEdited) {
          this.getData(this.id);
        }
      });
    } else if (
      this.data.item.productType === ProductType.EcSite ||
      this.data.item.productType === ProductType.Malymoon
    ) {
      let contentStyle = {
        overflow: 'auto',
        padding: '40px',
        borderRadius: '8px',
      };
      let width = '814px';
      let height = 'auto';

      if (this.isMobileScreen) {
        contentStyle = {
          overflow: 'auto',
          padding: '24px',
          borderRadius: '8px',
        };
        width = '358px';
        height = '500px';
      }
      this.ref = this.dialogService.open(ItemDetailCreatorManuallyComponent, {
        data: { id: this.id, isView: true, isEdit: isEdited },
        width: width,
        height: height,
        contentStyle: contentStyle,
        styleClass: 'b-r-8',
        baseZIndex: 10000,
        maximizable: false,
        showHeader: false,
      });
      this.ref.onClose.subscribe(dataReturn => {
        if (isEdited) {
          this.getData(this.id);
        }
      });
    }
  }

  getTextAddWarning() {
    if (!this.checkInfor?.isVerifyEmail) {
      return this.localizationService.instant('::CreatorDonate:AddWishList:AddWarningCheckOut');
    }
  }
}
