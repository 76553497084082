import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUserService } from '@proxy/users';
import { RecoverEmailDto } from '@proxy/users/dtos';
import { catchError } from 'rxjs';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-recover-email',
  templateUrl: './recover-email.component.html',
  styleUrls: ['./recover-email.component.scss'],
})
export class RecoverEmailComponent extends AppComponentBase implements OnInit {
  userId: string;
  resetToken: string;
  codeRecover: string;
  visibleSuccess = false;
  visibleFailed = false;
  resetPasswordCode: string;
  constructor(
    private _router: Router,
    private router: ActivatedRoute,
    private userManagerService: AppUserService,
    injector: Injector
  ) {
    super(injector);
  }
  ngOnInit(): void {
    this.router.queryParams.subscribe(params => {
      this.userId = params['userId'];
      this.codeRecover = params['codeRecover'];
      this.resetToken = params['resetToken'];
      this.userManagerService
        .recoverEmail(this.userId, this.codeRecover)
        .pipe(
          catchError(err => {
            this.visibleFailed = true;
            return null;
          })
        )
        .subscribe((res: RecoverEmailDto) => {
          if (res.isSuccess == true) {
            this.visibleSuccess = true;
            this.resetPasswordCode = res.resetPasswordCode;
          } else {
            this.visibleFailed = true;
          }
        });
    });
  }

  changePassword() {
    this._router.navigate(['/auth/reset-password'], {
      queryParams: {
        userId: this.userId,
        resetToken: this.resetToken,
        userconfirm: this.resetPasswordCode,
      },
    });
  }
}
