<p-sidebar
  (showCloseIcon)="(false)"
  class="text-start gift-box"
  [(visible)]="visible"
  position="right"
  [dismissible]="true"
  (onHide)="onHide()"
>
  <p-toast key="tms" position="bottom-left"></p-toast>
  <ng-template pTemplate="header">
    <span>
      <img class="custom-sidebar-img" src="assets/images/icon/svg/giptbox.svg" alt="" />
      {{ '::Wishlist:CheckOut:GiftBox' | abpLocalization }}
    </span>
    <br />
  </ng-template>
  <div *ngFor="let items of dataGiptbox?.items; index as i">
    <label class="f-s-12 f-w-500 m-b-8 color-111" for="">
      {{ items.giptCreatorName }} {{ '::Wishlist:GiftBox:GiftFor' | abpLocalization }}
    </label>
    <div
      *ngFor="let item of items?.giftInCart; index as i"
      [ngClass]="{
        'border-gipt-box-top':
          item.priceStatus && item.priceStatus.priceStatus !== PriceStatusType.Equal && i !== 0,
      }"
    >
      <div *ngIf="!item.isDeleted" class="giptbox-item m-b-8">
        <div class="giptbox-item-img">
          <img class="img-gipt-box" [src]="getImageFromUrl(item?.image, widthType.W120)" alt="" />
        </div>
        <div class="giptbox-item-content">
          <p class="line-camp-4 f-s-14 color-111 f-w-500 m-b-7">{{ item?.title }}</p>
          <p (click)="deleteGiptbox(item.id, items?.giftInCart, items)" class="text-delete">
            {{ '::Wishlist:CheckOut:Delete' | abpLocalization }}
          </p>
        </div>
        <div class="giptbox-item-price">
          <span class="f-s-14 color-111 f-w-500">¥{{ item?.fund | tienTe }} </span> <br />
          <span class="f-s-10 color-718096 f-w-500">
            {{
              item?.isDonateAll
                ? ('::Wishlist:CheckOut:Gift' | abpLocalization)
                : ('::Wishlist:CheckOut:PartGift' | abpLocalization)
            }}
          </span>
        </div>
      </div>

      <div
        *ngIf="!item.isDeleted && item.priceStatus && item.priceStatus?.priceStatus !== PriceStatusType.Equal"
        class="action-change-price"
      >
        <p class="color-3182CE f-s-12 f-w-500">
          {{
            '::Wishlist:FanView:Donate:ConfirmDonateSubTitle'
              | abpLocalization
                : transformPrice(item.priceStatus?.priceOld)
                : transformPrice(item.priceStatus?.priceNew)
          }}
        </p>

        <div class="gipt-price-change-btn-action">
          <p-button
            *ngIf="item.priceStatus?.priceStatus === PriceStatusType.Increase"
            (click)="confirmPrice(item, true)"
            styleClass="w-100 m-b-8"
          >
            <span class="f-w-500">{{ '::Wishlist:GiftBox:PayFull' | abpLocalization }}</span>
            <span class="f-w-100 m-l-24">¥{{ item.priceStatus?.priceNew | tienTe }}</span>
          </p-button>
          <p-button (click)="confirmPrice(item)" styleClass="p-button-outlined w-100 m-b-8">
            <span class="f-w-500">{{ '::Wishlist:GiftBox:PayPart' | abpLocalization }}</span>
            <span class="f-w-100 m-l-24"
              >¥{{
                item.priceStatus.priceStatus === PriceStatusType.Increase
                  ? item.fund
                  : (item.priceStatus.fullDonate | tienTe)
              }}</span
            >
          </p-button>
          <p-button (click)="changePrice(item)" styleClass="custom-btn-cancel w-100">
            <span class="f-w-500 color-111">
              {{ '::Wishlist:GiftBox:ChangeAmount' | abpLocalization }}
            </span>
          </p-button>
        </div>
      </div>
    </div>
    <hr class="p-0" />
  </div>
  <div *ngIf="dataGiptbox?.totalDonate === 0">
    <label class="f-s-16 color-111 f-w-500" for="">
      {{ '::Wishlist:GiftBox:NoData' | abpLocalization }}</label
    >
  </div>
  <ng-template pTemplate="footer">
    <div class="row m-b-40 m-t-24 f-s-20 color-111 f-w-500">
      <div class="col-6">
        <label>{{ '::Wishlist:CheckOut:Total' | abpLocalization }}</label>
      </div>
      <div class="col-6 text-end">¥{{ dataGiptbox?.totalDonate | tienTe }}</div>
    </div>
    <p-button
      [disabled]="dataGiptbox?.totalGift === 0"
      (click)="dataGiptbox?.totalGift > 0 && checkOut()"
      styleClass="p-button-rounded  w-100 button-content"
      [loading]="isCheckout"
      [disabled]="isCheckout"
    >
      <span class="f-s-13 f-w-500 me-2">
        {{ '::Wishlist:GiftBox:CheckOut' | abpLocalization }}
      </span>
    </p-button>
  </ng-template>
</p-sidebar>
