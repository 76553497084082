<div
  class="header-menu-wrap d-flex align-items-center justify-content-between"
  [ngClass]="screen === 'page' ? 'page-menu-wrap' : 'home-menu-wrap'"
>
  <a routerLink="/home" class="logo">
    <img src="./assets/images/logo/logo1.svg" alt="" />
  </a>

  <div *ngIf="screen === 'home' || (!hasLoggedIn && screen === 'page')" class="page-header-actions">
    <a (click)="openNewWindow('/auth/sign-in')" class="btn-signin color-111">
      {{ '::SignIn:LoginButton' | abpLocalization }}
    </a>
    <a (click)="openNewWindow('/auth/sign-up')" class="btn-signup color-fff">
      {{ '::SignIn:SignUpLable' | abpLocalization }}
    </a>
  </div>

  <ng-container *ngIf="hasLoggedIn && screen === 'page'">
    <div
      *ngIf="!isMobileScreen"
      class="header-menu d-flex align-items-center justify-content-between"
    >
      <a
        *ngFor="let item of menuItems"
        [routerLink]="item.url"
        (click)="changeMenu(item?.url)"
        class="header-menu-item color-111 f-s-16"
      >
        <span
          class="eclipse-icon"
          [ngClass]="{ 'eclipse-icon-active': isMenuItemActive(item?.url) }"
        ></span>
        <img class="header-menu-item-icon" [src]="item?.icon" alt="" />
        {{ item?.text | abpLocalization }}
      </a>
    </div>
    <div class="header-menu-right d-flex align-items-center">
      <span (click)="logout()" class="btn-logout pointer">
        {{ '::CreatorDonate:Profile:Logout' | abpLocalization }}
      </span>

      <div *ngIf="!isMobileScreen" (click)="openGitpBox()" class="menu-cart-wrap pointer">
        <img src="assets/images/icon/svg/cart.svg" alt="" />
        <p-badge *ngIf="giftToltal !== 0" class="badge-custom" [value]="giftToltal"></p-badge>
      </div>
    </div>
  </ng-container>
</div>
