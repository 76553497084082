import { mapEnumToOptions } from '@abp/ng.core';

export enum ReasonType {
  PriceIncrease = 0,
  ShippingFeeIncrease = 1,
  OutOfStock = 2,
  OutOfStockPartially = 3,
  RefundFail = 4,
  WrongAddress = 5,
  MissingProductInformation = 6,
  Other = 7,
  After30DaysFromEnoughDonateNotConfirm = 8,
  CancelGiftAfter60Days = 9,
  CreatorHasDeletedAccount = 10,
  PriceDecrease = 11,
}

export const reasonTypeOptions = mapEnumToOptions(ReasonType);
