import { Component, Injector, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-confirm-donate-price',
  templateUrl: './confirm-donate-price.component.html',
  styleUrls: ['./confirm-donate-price.component.scss'],
})
export class ConfirmDonatePriceComponent extends AppComponentBase implements OnInit {
  data: any;
  fullDonate: number;
  constructor(
    injector: Injector,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.data = this.dynamicDialogConfig.data;
    let el = document.querySelector<HTMLElement>('.p-dialog');
    el.style.boxShadow = 'unset';
    this.getMoneyFullDonate();
  }

  closeDialog(data): void {
    this.dialogRef.close({ data: data });
  }

  getMoneyFullDonate() {
    if (this.data.priceStatus == this.PriceStatusType.Decrease) {
      this.fullDonate = this.data.fullDonate;
    } else if (this.data.priceStatus == this.PriceStatusType.Increase) {
      this.fullDonate = this.data.priceNew;
    }
  }
}
